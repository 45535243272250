<template>
  <div>
    <v-form ref="form">
      <v-text-field
        v-model="umUser.name"
        label="Name"
        required
        :rules="[v => !!v || 'Name is required']"
      ></v-text-field>

      <v-autocomplete
        :items="countries"
        item-text="title"
        item-value="code"
        v-model="umUser.countryCode"
        label="Country"
        required
        :rules="[v => !!v || 'Country is required']"
      ></v-autocomplete>

      <v-text-field
        :prefix="umUser.countryCode"
        v-model="umUser.mobileNumber"
        label="Contact"
        required
        :rules="[v => !!v || 'Contact Number is required']"
      ></v-text-field>

      <v-text-field v-model="umUser.email" label="E-mail" required :rules="emailRules"></v-text-field>

      <v-text-field
        v-if="type != 'UPDATE'"
        v-model="umUser.password"
        label="Password"
        clearable
        ref="password"
        :rules="passwordRules"
        :type="showPassword ? 'text' : 'password'"
        :append-icon="showPassword ? 'visibility' : 'visibility_off'"
        @click:append="showPassword = !showPassword"
      ></v-text-field>
    </v-form>

    <v-dialog width="600" v-model="dialog" v-if="type == 'UPDATE'">
      <v-card>
        <v-card-title>Reset Password</v-card-title>
        <v-container grid-list-md>
          <v-text-field
            v-model="umUser.password"
            label="New Password"
            clearable
            ref="password"
            :rules="passwordRules"
            :type="showPassword ? 'text' : 'password'"
            :append-icon="showPassword ? 'visibility' : 'visibility_off'"
            @click:append="showPassword = !showPassword"
            solo
          ></v-text-field>
        </v-container>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" @click="updateUser">Reset</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-btn color="primary mr-2" @click="$router.push({ name: 'UserIndex' })">Back</v-btn>
    <v-btn color="primary mr-2" @click="updateUser" v-if="type == 'UPDATE'">Update</v-btn>
    <v-btn color="primary mr-2" @click="resetPassword" v-if="type== 'UPDATE'">Reset Password</v-btn>
  </div>
</template>

<script>
import RestResource from "@/services/dataServiceAdmin.js";

const service = new RestResource();

export default {
  props: {
    umUser: Object,
    type: String
  },
  data() {
    return {
      dialog: false,
      showPassword: false,
      countries: [],
      emailRules: [
        v => !!v || "E-mail is required",
        v => /.+@.+\..+/.test(v) || "E-mail must be valid"
      ],
      mobileNumberRules: [value => !!value || "Mobile number required."],
      passwordRules: [
        value => !!value || "Password required.",
        value => (value || "").length >= 8 || `Min 8 characters`
      ]
    };
  },
  mounted() {
    this.getCountry();
  },
  methods: {
    async getCountry() {
      let r = await service.getConfig("countries");

      this.countries = r.data;
    },
    updateUser() {
      if (this.$refs.form.validate()) {
        this.updateUMUser(this.umUser);
      }
    },
    updateUMUser(umUser) {
      umUser.updated_at = Date.now();
      umUser.userId = umUser._id

      // TODO: Add created_by and updated_by

      this.$setLoader();

      service.updateUMUser(umUser).then(() => {
        this.$disableLoader();
        alert("Successfully updated user");
        this.dialog = false
      }).catch(() => {
        this.$disableLoader();
        alert("Failed to update user");
        this.dialog = false
      });
    },
    resetPassword() {
      this.dialog = true;
    }
  }
};
</script>