<template>
  <v-card>
    <v-card-title>
      Edit User
    </v-card-title>

    <v-container>
      <UserForm
        :umUser="umUser"
        type="UPDATE"
      />
    </v-container>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceAdmin.js";
import UserForm from "./form.vue"

const service = new RestResource();

export default {
  components: {
    UserForm
  },
  data() {
    return {
      umUser: {},
      success: false,
      failure: false,
    };
  },
  mounted() {
    this.$setLoader()

    service.fetchUsers({ userIds: [ this.$route.params.id ] }).then(r => {
      this.$disableLoader()

      this.umUser = r.data[0]
    })
  }
};
</script>
